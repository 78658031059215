import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import SEO from "../../../components/seo"
import PageWrapper from "../../../components/PageWrapper"
import { Container, Row } from "react-bootstrap"
import { useContent } from "../../../hooks/useContent"
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect"
import PageTitle from "../../../components/Core/PageTitle"
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import FaqSection from "../../../components/Sections/FaqSection"
import FaqProcess from "../../../components/Core/FaqProcess"
import QuickFeedback from "../../../components/QuickFeedback"
import SectionSeparator from "../../../components/Core/SectionSeparator"

const Content = styled.div`

    & h2 {
        line-height: 1 !important;
    }

`

const headerConfig = {
    paddingBottom: 10,
    variant: 'minimal'
};

const InfoMentorship = () => {
    
    const content = useContent()
    
    return (
        <>
            <UnauthenticatedTemplate>
                <NoAuthRedirect />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title={content.get('what-you-need-to-know-new-position-page-title', true)} />
                    <Content className="">
                        <Container>
                            <Breadcrumb className="mt-20">
                                <Breadcrumb.Item active><Link to="/">Home</Link></Breadcrumb.Item>
                                <Breadcrumb.Item href="/what-you-need-to-know">
                                    <Link to="/what-you-need-to-know">{content.get('what-you-need-to-know-page-title', true)}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {content.get('what-you-need-to-know-new-position-page-title', true)}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Container>
                        <PageTitle 
                            align="left"
                            title={content.get('what-you-need-to-know-new-position-page-title', true)}
                        />
                        <div class="inner-content">
                            <FaqSection>
                                <FaqProcess
                                    tabs={[
                                        {
                                            title: "Applying",
                                            content: content.get('section-new-position-applying', false)
                                        },
                                        {
                                            title: "Interview process",
                                            content: content.get('section-new-position-interview', false)
                                        },
                                        {
                                            title: "Transition to new position",
                                            content: content.get('section-new-position-transition', false)
                                        },
                                    ]}
                                />
                            </FaqSection>
                        </div>
                        <SectionSeparator fullWidth={true} />
                        <Container className="py-14">
                            <Row className="justify-content-center">
                                <h3 className="font-family-primary font-size-5 text-center mb-8">Did you find the answer to your question?</h3>
                            </Row>
                            <Row className="justify-content-center">
                                <QuickFeedback category="found-answer-to-question" />
                            </Row>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default InfoMentorship